body {
  scroll-behavior: smooth;
  margin: 0;

  background-image: url("./assets/images/background.jpg");
  height:'100vh';
  background-size: 250%;
  background-position: -100px;

  
}

