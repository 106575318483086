.bookmenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-weight: bolder;
    font-size: 28px;
  }
  p {
    text-align: left;
  }

  .book {
    color: rgb(0, 91, 91);
    background-color: white;
    padding: 12px 18px;
    border-radius: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: ease 0.5s;

    &:hover {
      color: white;
      background-color: rgb(0, 167, 167);
    }
  }
}
