.freeconsultation{
    
    .freeconsultation-container
    {
        display: flex;
        flex: 2;
        
        .freeconsultation-left{
            background-color: rgba($color: #000000, $alpha: 0.7);
            color: white;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .freeconsultation-h2{
                font-size: 30px;
                line-height: 0;
            }
            .freeconsultation-h3{
                font-size: 40px;
                line-height: 0;
            }
            .freeconsultation-header{
                color: white;
                font-size: 70px;
            }
            .freeconsultation-whatsapp{
                color: white;
                background-color: rgb(20, 174, 118);
                padding: 20px 80px;
                border-radius: 40px;
                cursor: pointer;


                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                
                font-size: 24px;
                a{
                    color: white;
                    text-decoration: none;
                }
                &:hover{
                    transition: transform 400ms;
                    transform: scale(1.1);
                    background-color: rgb(27, 214, 145);

                   
                }

                .freeconsultation-icon{
                    font-size: 40px;
                }
                
            }
            
        }
        .freeconsultation-right{
            display: flex;
            flex: 1;
        }
    }
   
}

@media screen and (max-width: 700px){
    .freeconsultation{
        .freeconsultation-container
        {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 2;
            
            .freeconsultation-left{
                background-color: rgba($color: #000000, $alpha: 0.7);
                color: white;
                display: flex;
                flex: 1;
                justify-content: center;
                align-items: center;
                flex-direction: column;
    
                .freeconsultation-h2{
                    font-size: 20px;
                    line-height: 0;
                }
                .freeconsultation-h3{
                    font-size: 30px;
                    line-height: 0;
                }
                .freeconsultation-header{
                    color: white;
                    font-size: 50px;
                }
                .freeconsultation-whatsapp{
                    color: white;
                    background-color: rgb(20, 174, 118);
                    padding: 20px 80px;
                    border-radius: 40px;
                    cursor: pointer;
    
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    
                    font-size: 16px;
                    a{
                        color: white;
                        text-decoration: none;
                    }
                    &:hover{
                        transition: transform 400ms;
                        transform: scale(1.1);
                        background-color: rgb(27, 214, 145);
    
                       
                    }
    
                    .freeconsultation-icon{
                        font-size: 30px;
                    }
                    
                }
                
            }
            .freeconsultation-right{
                display: flex;
                flex: 1;
            }
        }
       
    }
}