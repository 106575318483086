.navbar{
    height: 80px;
    background-color: #004d67;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .icon{
        color: white;
        visibility: hidden;
    }

    .menu-responsive{
        display: none;
    }
    .menu{
        height: 100%;
        list-style: none;
        display: flex;
        gap: 20px;
        font-weight: 600;
        justify-content: center;
        align-items: center;
        
        .navbar-link{
            height: 100%;
            display: flex;
            align-items: center;
           
        }

        .navbar-subnav{
            float: left;
            overflow: hidden;
            text-decoration: none;
            color: white;
            
            
            .navbar-subnav-content{
                display: none;
                position: absolute;
                left: 0;
                background-color: rgb(0, 77, 103);
                width: 100%;
                z-index: 500;
                padding: 20px 10px;
            }
            &:hover{
                
                background-color: rgba(0, 0, 0, 0.236);
                border-bottom: 5px solid rgb(0, 155, 155);
                transition: transform 400ms;
            }

            &:hover .navbar-subnav-content {
                
                display: flex;
                gap: 10px;
               
              }
        }

        li{
            color: white;
            height: 100%;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0px 10px;
            &:hover{
                
                background-color: rgba(0, 0, 0, 0.236);
                border-bottom: 5px solid rgb(0, 155, 155);
                transition: transform 400ms;
            }

        }


    }
    .right-menu{
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        .contact-menu{
            background-color: white;
            color: rgb(0, 124, 177);
            border: none;
            padding: 10px 20px;
            font-weight: 20px;
            font-weight: 700;
            border-radius: 5px;
            cursor: pointer;
        }
    }

   
}

@media screen and (max-width: 700px){
    .navbar{
        height: 80px;
        background-color: #004d67;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        .icon{
            color: white;
            visibility: visible;
        }
        .menu{
            display: none;
        }
        .menu-responsive{
            height: 100%;
            list-style: none;
            display: flex;
            gap: 20px;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            display: none;
            .navbar-link{
                height: 100%;
                display: flex;
                align-items: center;
               
            }
    
            .navbar-subnav{
                float: left;
                overflow: hidden;
                text-decoration: none;
                color: white;
                
                
                .navbar-subnav-content{
                    display: none;
                    position: absolute;
                    left: 0;
                    background-color: rgb(0, 77, 103);
                    width: 100%;
                    z-index: 500;
                    padding: 20px 10px;
                }
                &:hover{
                    
                    background-color: rgba(0, 0, 0, 0.236);
                    border-bottom: 5px solid rgb(0, 155, 155);
                    transition: transform 400ms;
                }
    
                &:hover .navbar-subnav-content {
                    
                    display: flex;
                    gap: 10px;
                   
                  }
            }
    
            li{
                color: white;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0px 10px;
                &:hover{
                    
                    background-color: rgba(0, 0, 0, 0.236);
                    border-bottom: 5px solid rgb(0, 155, 155);
                    transition: transform 400ms;
                }
    
            }
    
    
        }
        .right-menu{
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            display: none;
            .contact-menu{
                background-color: white;
                color: rgb(0, 124, 177);
                border: none;
                padding: 10px 20px;
                font-weight: 20px;
                font-weight: 700;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    
       
    }

    .navbar-responsive{
        height: 80px;
        background-color: #004d67;
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: space-around;
        transition: all;
        .icon{
            position: fixed;
            color: white;
            visibility: visible;
            
        }
        .menu{
            display: none;
        }
        .menu-responsive{
            transition: display  0s, opacity 1.5s linear;
            z-index: 1000;
            position: relative;
            top: 200px;
            left: 0;
            background-color: #004d67;
            height: fit-content;
            width: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            gap: 20px;
            font-weight: 600;
            justify-content: center;
            align-items: center;
            padding: 50px;
            .navbar-link{
                height: 100%;
                display: flex;
                align-items: center;
               
            }
    
            .navbar-subnav{
                float: left;
                overflow: hidden;
                text-decoration: none;
                color: white;
                background-color: aqua;
                height: 150px;
                
                .navbar-subnav-content{
                    display: none;
                    position: absolute;
                    left: 0;
                    background-color: rgb(0, 77, 103);
                    width: 100%;
                    z-index: 500;
                    padding: 20px 10px;
                }
                &:hover{
                    
                    background-color: rgba(0, 0, 0, 0.236);
                    border-bottom: 5px solid rgb(0, 155, 155);
                    transition: transform 400ms;
                }
    
                &:hover .navbar-subnav-content {
                    
                    display: flex;
                    gap: 10px;
                   
                  }
            }
    
            li{
                color: white;
                height: 100%;
                display: flex;
                align-items: center;
                cursor: pointer;
                padding: 0px 10px;
                &:hover{
                    
                    background-color: rgba(0, 0, 0, 0.236);
                    border-bottom: 5px solid rgb(0, 155, 155);
                    transition: transform 400ms;
                }
    
            }
    
    
        }
        .right-menu{
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            display: none;
            .contact-menu{
                background-color: white;
                color: rgb(0, 124, 177);
                border: none;
                padding: 10px 20px;
                font-weight: 20px;
                font-weight: 700;
                border-radius: 5px;
                cursor: pointer;
            }
        }
    
       
    }
  }
  