.mainmenu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
    .title{
        font-weight: bolder;
        font-size: 28px;
        margin-bottom: 15px;
    }
    .menu-list{
        color: white !important; 
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        list-style:circle;
        font-size: 18px;
        padding: 0px 10px;
        margin: 0px;

        .navbar-link{
            color: white;
        }
    }
}