.footer{
    height: 500px;
    background-color: #004d67;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    
    .container-footer{
        display: flex;
        justify-content: center;
        align-items: start;
        color: white;
        gap: 100px;
        padding-top: 40px;

        .contacts-footer{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 5px;
        }
    }
    
}

@media screen and (max-width: 700px) {
    .footer{
        height: fit-content;
        background-color: #004d67;
        display: flex;
        
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 10;
        padding-bottom: 50px;
        
        .container-footer{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: start;
            color: white;
            gap: 100px;
            padding-top: 40px;
    
            .contacts-footer{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 5px;
            }
        }
        
    }
  }