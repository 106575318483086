.reservation{
    width: fit-content;
    
    h1{
        color: black;
    }
    .error {
        margin: 0;
        color: #bf1616;
      }
    .form-container{
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        display: flex;
        width: fit-content;
        flex-direction: column;
        gap: 20px;
        padding: 30px;
        background-color: white;
        border-radius: 15px;
        .form-row{
            display: flex;
            gap: 15px;
           
        }
        .form-button{
            border: none;
            padding: 10px 30px;
            width: fit-content;
            border-radius: 5px;
            background-color: rgb(0, 75, 128);
            color: white;
            font-weight: bold;
            cursor: pointer;
            //z-index: 30;
            
        }
    }
}