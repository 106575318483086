.contacts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .title {
    font-weight: bolder;
    font-size: 28px;
    margin-bottom: 15px;
  }
  .container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .item {
      display: flex;

      .item1 {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: 18px;
        padding: 0px 10px;
      }

      a:link {
        background-color: transparent !important;
        text-decoration: none !important;
        color: white;
      }
    }
  }
}
