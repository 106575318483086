.floatingbutton{
    .floatingbutton-add{
        background-color: rgb(0, 0, 0);
        padding: 10px;
        border-radius: 10px;
        cursor: pointer;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        position: absolute;
        left: 0;
        bottom: 0;
        margin-left: 1.5%;
        margin-bottom: 2.3%;
    }

    .fab-container {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        flex-direction: column-reverse;
        position: fixed;
        right: 2em;
        bottom: 2em;
        max-height: 52px;
      
        &.open {
          max-height: max-content;
        }
      
        li {
          border-radius: 50%;
          background-color: #ffffff;
          box-shadow: 0 3px 6px lightgrey;
          display: grid;
          place-items: center;
          margin: 8px 0;
          font-size: 28px;
          padding: 12px;
          cursor: pointer;
          position: relative;
        }
      
        .fab-button {
          background-color: #000000;
      
          svg {
            fill: white;
          }
        }
      
        .fab-action {
          transform: translateY(50px) scale(0);
          transition: transform 300ms, opacity 300ms;
          opacity: 0;
          svg {
            fill: rgb(0, 65, 88);
          }
      
          &:hover {
            .tooltip {
              transform: translateX(-100%) scale(1);
              opacity: 1;
            }
          }
      
          &.open {
            transform: translateY(0) scale(1);
            opacity: 1;
          }
      
          .tooltip {
            padding: 4px 6px;
            font-size: 12px;
            position: absolute;
            left: -12px;
            transform: translateX(-75%);
            background-color: #353b48;
            border-radius: 4px;
            color: white;
            opacity: 0;
            transition: transform 300ms, opacity 300ms;
            user-select: none;
          }
        }
      }
}