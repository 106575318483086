.whoweare{
   
    height: 600px; 
    display: flex;
    justify-content: center;
    align-items: center;  
    .whoweare-container{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        border-radius: 30px;
        padding: 50px;
        margin: 3%;
        height: fit-content;
        background-color: white;
    
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        .title{
            color: #087f5b;
            font-weight: bolder;
            font-size: 28px;
        }
    
        p{
            text-align: start;
            font-size: 18px;
            color: #7D7D7D;
            line-height: 2;
        }
    }
    
}

@media screen and (max-width: 700px){
    .whoweare{
   
        height: fit-content; 
        display: flex;
        justify-content: center;
        align-items: center;  
        .whoweare-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 30px;
            padding: 50px;
            margin: 3%;
            height: fit-content;
            background-color: white;
        
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            .title{
                color: #087f5b;
                font-weight: bolder;
                font-size: 28px;
            }
        
            p{
                text-align: start;
                font-size: 18px;
                color: #7D7D7D;
                line-height: 2;
            }
        }
        
    }
  }