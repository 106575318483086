.contact {
  .contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 150px;
    padding: 50px 0px;
    color: white;

    .contact-form {
      background-color: white;
    }
  }
}

@media screen and (max-width: 700px) {
  .contact {
    color: white;
    .contact-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 150px;
      padding: 50px 0px;

      .contact-form {
        background-color: white;
      }
    }
  }
}
