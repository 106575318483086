.boxmenu {
  .boxmenu-container {
    background-color: rgb(79, 0, 0);
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    position: fixed;
    left: 0em;
    bottom: 20em;
    height: 220px;
    width: 60px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    cursor: pointer;

    .boxmenu-link {
      overflow: hidden;
      text-decoration: none;
      color: white;
    }
    &:hover {
      color: white;
      background-color: rgb(136, 0, 0);
      transform: scale(1.05);
      transition: transform 400ms;
    }
    .boxmenu-text {
      font-size: large;
      transform: rotate(-90deg);
      white-space: nowrap;
    }
  }
}
