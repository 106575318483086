.single1 {
  .single-container1 {
    display: flex;

    .single-container2 {
      display: flex;
      .single-image-container1 {
        padding: 60px 60px;
        position: relative;
        display: flex;

        .single-image1 {
          height: 500px;
          width: 500px;
          object-fit: cover;
          border-radius: 10px;
          -webkit-box-shadow: 4px 8px 20px 2px rgba(0, 0, 0, 0.47);
          box-shadow: 4px 8px 20px 2px rgba(201, 201, 201, 0.47);
        }

        .single-title1 {
          right: -230px;
          top: 0px;
          padding: 100px 100px;
          background-color: rgba(0, 0, 0, 0.47);
          color: rgb(222, 222, 222);
          position: absolute;
          font-size: 34px;
          font-weight: bolder;
        }
      }

      .single-text-container1 {
        padding: 60px 60px;
        display: flex;
        align-items: flex-start;

        .single-text1 {
          padding-top: 190px;

          .single-p1 {
            color: #ffffff;

            display: flex;
            justify-items: flex-end;
            align-items: flex-end;
            p {
              padding: 30px;
              background-color: rgba(0, 0, 0, 0.47);
              height: 280px;
              overflow-y: scroll;
              text-align: left;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .single1 {
    .single-container1 {
      display: flex;

      .single-container2 {
        display: flex;
        flex-direction: column;
        .single-image-container1 {
          padding: 10px 10px;
          position: initial;
          display: flex;
          flex-direction: column;

          .single-image1 {
            height: 400px;
            width: 400px;
            object-fit: cover;
            border-radius: 10px;
            -webkit-box-shadow: 4px 8px 20px 2px rgba(0, 0, 0, 0.47);
            box-shadow: 4px 8px 20px 2px rgba(201, 201, 201, 0.47);
          }

          .single-title1 {
            padding: 30px 0px;

            background-color: rgb(219, 219, 219);
            color: rgb(0, 91, 148);
            position: inherit;
            font-size: 34px;
            font-weight: bolder;
          }
        }

        .single-text-container1 {
          padding: 10px 40px;
          display: flex;
          align-items: flex-start;
          .single-text1 {
            padding-top: 0px;

            .single-p1 {
              color: #ffffff;

              p {
                background-color: rgba(0, 0, 0, 0.47);
                height: 280px;
                overflow-y: scroll;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
