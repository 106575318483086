.callnow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 3;
  height: 400px;
  background-color: rgba(0, 0, 0, 0.47);

  .callnow-container {
    display: flex;
    flex: 3;
    height: 150px;
    padding: 40px 0px;
    .callnow-left {
      flex: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-left: 70px;
      gap: 30px;

      .callnow-phone-text {
        color: white;
        font-size: 30px;
      }

      .callnow-message-text {
        color: white;
        font-size: 26px;
      }
    }
    .callnow-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      font-size: large;
      font-weight: bolder;

      .callnow-phone {
        display: flex;
        color: white;
        justify-content: center;
        padding: 15px;
        align-items: center;
        background-color: rgb(0, 77, 103);
        border-radius: 20px;
        margin-left: 10%;
        margin-right: 10%;
        gap: 10px;
        cursor: pointer;
        transition: ease 0.3s;
        &:hover {
          -webkit-box-shadow: 1px 2px 5px 0.5px rgba(0, 0, 0, 0.47);
          box-shadow: 1px 2px 5px 1px rgba(201, 201, 201, 0.47);
        }

        a {
          color: white;
          text-decoration: none;
        }
      }

      .callnow-message {
        display: flex;
        color: white;
        justify-content: center;
        padding: 15px;
        align-items: center;
        background-color: rgb(0, 124, 106);
        border-radius: 20px;
        margin-left: 10%;
        margin-right: 10%;
        gap: 10px;
        cursor: pointer;
        transition: ease 0.3s;

        &:hover {
          -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
          box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .callnow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 3;

    background-color: rgba(0, 0, 0, 0.47);

    .callnow-container {
      display: flex;
      flex-direction: column;
      flex: 3;
      height: 150px;
      padding: 40px 0px;
      height: fit-content;
      gap: 30px;

      .callnow-left {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left: 70px;
        gap: 30px;

        .callnow-phone-text {
          color: white;
          font-size: 30px;
        }

        .callnow-message-text {
          color: white;
          font-size: 26px;
        }
      }
      .callnow-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 20px;
        font-size: large;
        font-weight: bolder;

        .callnow-phone {
          display: flex;
          color: white;
          justify-content: center;
          padding: 15px;
          align-items: center;
          background-color: rgb(0, 77, 103);
          border-radius: 20px;
          margin-left: 10%;
          margin-right: 10%;
          gap: 10px;
          cursor: pointer;
          &:hover {
            -webkit-box-shadow: 1px 2px 5px 0.5px rgba(0, 0, 0, 0.47);
            box-shadow: 1px 2px 5px 1px rgba(201, 201, 201, 0.47);
          }

          a {
            color: white;
            text-decoration: none;
          }
        }

        .callnow-message {
          display: flex;
          color: white;
          justify-content: center;
          padding: 15px;
          align-items: center;
          background-color: rgb(0, 124, 106);
          border-radius: 20px;
          margin-left: 10%;
          margin-right: 10%;
          gap: 10px;
          cursor: pointer;

          &:hover {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
          }
        }
      }
    }
  }
}
