.slider {
  height: 700px;
  position: relative;

  .slider-title {
    left: 90px;
    top: 400px;
    font-size: 80px;
    position: absolute;
    color: white;
    z-index: 10;
  }
  .swiper {
    height: 680px;
    .slide {
      .slide-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}
