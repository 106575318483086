.curosal {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    color: #343a40;
    line-height: 1;
  }

  .curosal-container{
   height: 600px;
   display: flex;
   justify-content: center;
   align-items: center;
  }

  .carousel {
    width: 800px;
    margin: 100px auto;
    background-color: #004d67;
    padding: 32px 48px 32px 86px;

    border-radius: 8px;
    position: relative;

    display: flex;
    align-items: center;
    gap: 86px;

    img {
        height: 200px;
        border-radius: 8px;
        transform: scale(1.5);
        box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
      }
  }

  .testimonial-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 32px;
    color: #e6fcf5;
  }

  .testimonial-author {
    font-size: 14px;
    margin-bottom: 4px;
    color: #c3fae8;
  }

  .testimonial-job {
    font-size: 12px;
    color: #c3fae8;
  }

  .btn {
    background-color: #fff;
    border: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    position: absolute;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn--left {
    left: 0;
    top: 50%;
 
    transform: translate(-50%, -50%);
  }

  .btn--right {
    right: 0;
    top: 50%;

    transform: translate(50%, -50%);
  }

  .btn-icon {
    height: 24px;
    width: 24px;
    stroke: #087f5b;
  }
  .dots {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 32px);

    display: flex;
    gap: 12px;
  }

  .dot {
    height: 12px;
    width: 12px;
    background-color: #fff;
    border: 2px solid #087f5b;
    border-radius: 50%;
    cursor: pointer;
  }

  .dot--fill {
    background-color: #087f5b;
  }

  @media screen and (max-width: 700px){
    .curosal {
      display: none;
    }
  }