.about{
    display: flex;
    height: 700px;
    justify-content: center;
    align-items: center;
    
    .about-container{
        background-color: rgba(0, 0, 0, 0.47);
        border-radius: 30px;
        height: fit-content;
        display: flex;
        flex: 3;
        margin: 30px 30px;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);

        .left{
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border-radius: 30px 0px 0px 30px;
            padding: 50px;
            
           
            .title{
                color: white;
                font-weight: bolder;
                font-size: 28px;
            }
    
            p{
                text-align: start;
                font-size: 16px;
                color:  #ffffff;
                line-height: 2;
    
            }
    
            .read{
                color: #ffffff;
                padding: 15px 25px;
                border-radius: 10px;
                font-weight: 700;
                background-color: rgba($color: #000000, $alpha: 0.0);
                cursor: pointer;
                font-size: 16px;
                border:2px solid #ffffff;
                position: relative;
                overflow: hidden;
    
            }
    
            .read::after{
                content: "Read more";
                color: rgb(0, 77, 103);
                padding: 15px 25px;
                border-radius: 10px;
                font-weight: 700;
                background-color: #ffffff;
                border:3px solid #ffffff;
                cursor: pointer;
                font-size: 16px;
                
                position: absolute;
                top: -2px;
                left: -140px;
                transition: 1s all;
            }
    
            .read:hover.read::after{
                transform: translateX(140px);
            }

        }
    
        .right{
            flex: 1;
            .about-image{
                object-fit: cover;
                height: 100%;
                width: 100%;
                border-radius: 0px 30px 30px 0px;
            }
        }
    }

}

@media screen and (max-width: 700px){
    .about{
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .about-container{
            background-color: rgba(0, 0, 0, 0.47);
            border-radius: 30px;
            height: fit-content;
            display: flex;
            flex: 3;
            margin: 30px 30px;
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    
            .left{
                flex: 2;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 30px 0px 0px 30px;
                padding: 50px;
                
               
                .title{
                    color: white;
                    font-weight: bolder;
                    font-size: 28px;
                }
        
                p{
                    text-align: start;
                    font-size: 16px;
                    color:  #ffffff;
                    line-height: 2;
        
                }
        
                .read{
                    color: #ffffff;
                    padding: 15px 25px;
                    border-radius: 10px;
                    font-weight: 700;
                    background-color: rgba($color: #000000, $alpha: 0.0);
                    cursor: pointer;
                    font-size: 16px;
                    border:2px solid #ffffff;
                    position: relative;
                    overflow: hidden;
        
                }
        
                .read::after{
                    content: "Read more";
                    color: rgb(0, 77, 103);
                    padding: 15px 25px;
                    border-radius: 10px;
                    font-weight: 700;
                    background-color: #ffffff;
                    border:3px solid #ffffff;
                    cursor: pointer;
                    font-size: 16px;
                    
                    position: absolute;
                    top: -2px;
                    left: -140px;
                    transition: 1s all;
                }
        
                .read:hover.read::after{
                    transform: translateX(140px);
                }
    
            }
        
            .right{
                flex: 1;
                .about-image{
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    border-radius: 0px 30px 30px 0px;
                }
            }
        }
    
    }
    
}