.ourservices{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
    gap: 10px;
    margin-left: 10%;
    margin-right: 10%;
    .services-item{
        border-radius: 20px;
        height: 300px;
        width: 300px;
        background-color: rgb(255, 255, 255);
        overflow: hidden;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        position: relative;
        cursor: pointer;
        .services-title{
          font-weight: bold;
        }

        .ourservices-image{
          width: 100%;
          height: 100%;
          object-fit:cover;
        }

        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: #007c6a;
            overflow: hidden;
            width: 100%;
            height: 0;
            transition: .5s ease;

            display: flex;
            justify-content: center;
            align-items: center;
          }
          
          .services-text{
            padding: 0px 20px;
            color: white;
            font-size: large;
            position: absolute;
            overflow: hidden;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
          }

          &:hover .overlay {
            height: 100%;
          }
          
        .services-title{
            color: white;
            background-color: #007c6a;
            padding: 20px 0px;
        }
    }
}

@media screen and (max-width: 700px){
  .ourservices{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0px;
    gap: 10px;
    margin-left: 10%;
    margin-right: 10%;}
}