.single {
  .single-container {
    height: 500px;
    height: fit-content;
    padding: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;

    .single-image-container {
      height: 300px;
      width: 300px;
      border-radius: 25px;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      overflow: hidden;
      cursor: pointer;
      transition: ease 0.4s;
      &:hover {
        transform: scale(1.1);
      }
      .single-title {
        color: white;
        font-size: large;
        font-weight: bold;
        background-color: rgb(0, 111, 126);
        padding: 20px 0px;
        text-transform: uppercase;
      }
      .single-image {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        object-fit: cover;
      }
    }
  }
}
