.socialmedia{
    display: flex;
    align-items: center;
    gap: 5px;

 
    .icon{
        font-size: 32px;
        cursor: pointer;
    }
    .youtube{
        font-size: 42px;
        cursor: pointer;
    }

}