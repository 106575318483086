.firstvisit{
    display: flex;
    flex: 3;
    background-color: rgba(0, 0, 0, 0.47);
    .firstvisit-container{
        .firstvisit-h1{
           color: white;
        }
        height: 500px;
        margin: 0px 40px;
        .firstvisit-left{
            flex: 3;
            padding: 50px;
            
            border-radius: 30px 0px 0px 30px;
            .firstvisit-item{
                display: flex;
                gap: 20px;
                margin-bottom: 20px;
                color: white;
                
                
                .firstvisit-item-icon{
                    color:rgb(0, 77, 103);
                    background-color: white;
                    border-radius: 50%;
                    padding: 20px;
                    
                }
                .firstvisit-item-box{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    gap: 15px;
                    
                    .firstvisit-item-title{
                        font-size: 24px;
                        font-weight: bold;
                       }

                       .firstvisit-item-text{
                        font-size: large;
                       }
                }
               
            }
        }å

        .firstvisit-right{
            flex: 1;
            background-color: black;
        }
    }

}

@media screen and (max-width: 700px){
    .firstvisit{
        display: flex;
        
        flex: 3;
        background-color: rgba(0, 0, 0, 0.47);
        .firstvisit-container{
            .firstvisit-h1{
               color: white;
            }
            height: fit-content;
            margin: 0px 40px;
            .firstvisit-left{
                flex: 3;
                padding: 50px;
                border-radius: 30px 0px 0px 30px;
                .firstvisit-item{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    margin-bottom: 20px;
                    color: white;
                    
                    
                    .firstvisit-item-icon{
                        color:rgb(0, 77, 103);
                        background-color: white;
                        border-radius: 50%;
                        padding: 10px;
                        
                    }
                    .firstvisit-item-box{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        
                        gap: 15px;
                        
                        .firstvisit-item-title{
                            font-size: 24px;
                            font-weight: bold;
                           }
    
                           .firstvisit-item-text{
                            font-size: large;
                           }
                    }
                   
                }
            }å
    
            .firstvisit-right{
                flex: 1;
                background-color: black;
            }
        }
    
    }
}